@import '../../../assets/scss/variables';

.bmce-toast-container {
  max-width: 660px;
  width: auto;
  padding-top: 65px;
  &.Toastify__toast-container--top-center {
    left: 50%;
    transform: translateX(-50%);
    margin-left: 0;
  }
}

.bmce-toast {
  color: white;
  box-shadow: rgba(0, 0, 0, 0.176) 0px 0px 8px;
  padding: 0;
  min-height: 40px;
  border-radius: 10px;
  background-color: white !important;
  border: 1px solid transparent;
  align-items: center;
  cursor: text;
  &.Toastify__toast--success {
    color: $secondary;
    border-color: $secondary;
    .icon {
      .icon-expired {
        fill: $secondary;
      }
    }
  }

  &.Toastify__toast--error {
    color: $error;
    border-color: $error;
  }
  &.Toastify__toast--warning {
    color: rgb(255, 139, 0);
    .icon {
      background-color: rgb(255, 171, 0);
    }
  }

  &.Toastify__toast--info {
    color: rgb(80, 95, 121);
    .icon {
      background-color: rgb(38, 132, 255);
    }
  }

  .Toastify__close-button {
    color: inherit;
    align-self: center;
    width: 45px;
    height: 30px;
    font-size: 0;
    outline: none;
    background: url('../../../assets/svg/x.svg') no-repeat center;
  }

  .bmce-toast-body {
    margin: 0;
    padding: 8px 0 8px 50px;
    position: relative;
    word-break: break-word;
    display: table-cell;
    .icon {
      position: absolute;
      left: 10px;
      top: 0;
      bottom: 0;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 3px;
      color: white;
    }
  }

  .bmce-toast-progress-bar {
    background-color: rgba(0, 0, 0, 0.2);
  }
}
