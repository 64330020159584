@import './_variables.scss';

body {
  min-height: 100%;
}

.on-chunk-loading {
  min-height: 200px;
  min-width: 200px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  .btn {
    margin-left: 10px;
  }
}

.navbar-light .navbar-toggler {
  color: transparent !important;
  border-color: transparent !important;
  margin: 15px;
  padding: 0px;
}

.navbar-light .navbar-toggler,
.navbar-light .navbar-toggler:focus {
  outline: none !important;
}

.navbar-light .navbar-toggler-icon {
  background-image: url('../svg/menuBlanc.svg') !important;
}

.text-nowrap {
  white-space: nowrap !important;
}
