@import './variables';
@import '../fonts/fonts.css';
$body-bg: rgb(236, 240, 241);

$spacer: 1rem !default;

$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25,
    ),
    //4px
      2:
      (
        $spacer * 0.5,
      ),
    //8px
      3: $spacer,
    //16px
      4:
      (
        $spacer * 1.5,
      ),
    //24px
      5:
      (
        $spacer * 3,
      ),
    //48px
      6:
      (
        $spacer * 4,
      ),
    //64px
      7:
      (
        $spacer * 5,
      ),
    //80px
      8:
      (
        $spacer * 6.25,
      ),
    //100px
      9:
      (
        $spacer * 7.5,
      ),
    //120px
      10:
      (
        $spacer * 9.375,
      ),
    //150px
      11:
      (
        $spacer * 11.5,
      ),
    //150px
      12:
      (
        $spacer * 15.5,
      ),
  ),
  $spacers
);
$theme-colors: (
  'primary': $primary,
  'warning': $secondary,
  'secondary': $secondary,
  'dark': $darkBlue,
);

@import '../../../node_modules/rfs/scss';
$enable-responsive-font-sizes: true;

body {
  overflow-x: hidden;
  height: 100%;
  min-height: 100%;
  font-family: 'tex_gyre_herosregular', sans-serif !important;
}

$font-size-base: 0.9rem;
$line-height-base: 1.4;

@import '../../../node_modules/bootstrap/scss/bootstrap';

///// Title /////
.title1 {
  font-size: 34px;
  font-family: 'tex_gyre_herosbold', sans-serif !important;
}
.title2 {
  font-family: 'tex_gyre_herosbold', sans-serif !important;
  color: $primary;
}
.title3 {
  font-family: 'tex_gyre_herosbold', sans-serif !important;
  color: $darkGray;
}

.title4 {
  font-size: 16px;
  font-family: 'tex_gyre_herosbold', sans-serif !important;
  color: $darkBlue;
  line-height: 26px;
}

.title4Regular {
  font-family: 'tex_gyre_herosregular', sans-serif !important;
  color: $darkBlue;
}
.s-34 {
  font-size: 34px;
}
.s-28 {
  font-size: 28px;
}
.s-26 {
  font-size: 26px;
}

.s-24 {
  font-size: 24px;
}

.s-18 {
  font-size: 18px;
}

.s-15 {
  font-size: 15px;
}

.s-14 {
  font-size: 14px;
}

.label {
  color: $inputColor;
  font-size: $spacer;
}
.font-regular {
  font-family: 'tex_gyre_herosregular', sans-serif !important;
}

.loanStepTitle {
  font-family: 'tex_gyre_herosbold', sans-serif !important;
  color: $primary;
  font-size: 25px;
  display: flex;
  @media (max-width: 1000px) {
    font-size: 20px;
  }
}

.p1 {
  font-size: 16px;
  line-height: 20px;
  color: $darkGray;
}

.p2 {
  font-size: 15px;
  line-height: 20px;
  color: $shallowGray;
}

///// Button /////
.btn-secondary {
  color: white;
}

.primaryButton {
  font-size: 1rem;
  font-family: 'tex_gyre_herosbold', sans-serif !important;
}

.secondaryButton {
  background-color: $secondary;
  border-color: $secondary;
  padding: 8px 40px;
  font-weight: 500px;
  color: white !important;
}

.searchBtn {
  padding: 8px;
  color: $darkBlue !important;
  display: flex;
  align-items: center;
}

.prospectBtn {
  line-height: 30px;
  background-color: $darkBlue;
  color: white !important;
  font-size: 1rem;
  font-family: 'tex_gyre_herosbold', sans-serif !important;
}

.btn-lg {
  font-size: 1.1rem;
  line-height: 2;
  font-family: 'tex_gyre_herosbold', sans-serif !important;
  letter-spacing: 0.5px;
}

.btn-md {
  font-size: 1rem;
  line-height: 1.8;
  font-family: 'tex_gyre_herosbold', sans-serif !important;
  letter-spacing: 0.5px;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #c4c8cc;
}

.tooltip-inner {
  background-color: $darkBlue;
  padding: 0.6rem 1rem;
  text-align: left;
  border-radius: 15px;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^='left'] .arrow::before {
  border-left-color: $darkBlue;
  margin-left: -1px;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^='top'] .arrow::before {
  border-top-color: $darkBlue;
}

.form-group {
  position: relative;
  margin-bottom: 1.5rem;
}

/*########## MODALS #############*/
.modal-footer {
  border-top: 0;
  padding-right: 4rem;
  padding-left: 4rem;
}

.modal-header {
  border-bottom: 0;
  padding-right: 2rem;
  padding-left: 2rem;
  padding-top: 2rem;

  .close {
    margin: 0 0 0 auto;
    width: 32px;
    height: 32px;
    border-radius: 10px;
    border-color: #f8f9fa;
    background: url('../svg/x.svg') no-repeat center #f8f9fa;
    opacity: 1;
    > * {
      display: none;
    }
  }
}

.modal-body {
  padding-right: 2rem;
  padding-left: 2rem;
}

.modal-backdrop {
  background: rgb(0, 0, 0) no-repeat padding-box !important;
}

.modal-content {
  border-radius: 30px;
  border: none;
}

.navbar {
  padding-top: 0;
  padding-bottom: 0;
}

/*###### Accordion  ##########*/
.customAccordion {
  margin: 80px auto 0;
  width: 50%;
}

.accordion-card {
  border-radius: 10px !important;
  margin-top: 12px;
}

.accordion-toggle-button {
  color: $darkBlue;
  padding: 0;
  border: none;
  text-decoration: none !important;
}
.accordion-toggle-header {
  display: flex;
  justify-content: space-between;
  background-color: $fancyGray;
  padding: 15px 40px;
  border-radius: 10;
  cursor: pointer;
  text-align: left;
}

.accordion-card-body {
  padding: 10px 40px 40px 40px;
}

.general-condition-url {
  color: $primary;
}

.gm-style .gm-style-iw-c {
  width: 260px !important;
}
