/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on November 5, 2019 */

@font-face {
    font-family: 'tex_gyre_herosbold';
    src: url('texgyreheros-bold.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'tex_gyre_herositalic';
    src: url('texgyreheros-italic.otf') format('opentype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'tex_gyre_herosregular';
    src: url('texgyreheros-regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}