$primary: #2980b9;
$secondary: #33cccc;
$info: #e2e7e8;
$darkBlue: #2c3e50;
$darkGray: #9f9f9f;
$shallowGray: #ecf0f1;
$liteGray: #fbfbfc;
$inputColor: #6e6e6e;
$error: #ff6b6b;
$opacityGray: #0000001A;
$fancyGray: #e2e7e8;
